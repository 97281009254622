import SecureApi from '@/flows/Authentication/services/SecureApi.js';
import { PollyClient, SynthesizeSpeechCommand } from '@aws-sdk/client-polly';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { checkIfMistakeTooltipShouldBeShown } from '@/student/helpers.js';
import {
    TrackingService,
    EVENTS,
} from '@/core/services/TrackingService/TrackingService.js';

export const Participants = {
    Moderator: 'moderator',
    AI: 'ai',
    Player: 'player',
};

export const TUTOR_CALL_COST_IN_AI_CREDITS = 20;
export const PLAYER_CTA_TYPES = {
    EXPLAIN_DIFFERENTLY: 'explainDifferent',
    WRONG_ANSWER: 'wrongAnswer',
};

let correctAnswerTimeoutHandler;
let incorrectAnswerTimeoutHandler;
let requestAbortController = new AbortController();
let alreadyClosing = false;

export default {
    namespaced: true,
    state: {
        showTutor: false,
        initialized: false,
        messages: [],
        math: null,
        loading: false,
        aiAvatar: 'tutor',
        playerAvatar: null,
        currentQuestion: null,
        messageAnimationPromises: [],
        showPlayerCtas: false,
        playerCtaType: PLAYER_CTA_TYPES.EXPLAIN_DIFFERENTLY,
        showAudioReplayButtons: false,
        lastAiMessageId: null,
        playersWrongAnswerValue: null,
        wrongAnswerHintUsed: null,
        pollyBufferSourceNode: null,
        usedToday: sessionStorage.getItem('aiTutor/usedToday') === 'true',
        pollyClient: null,
    },
    getters: {
        showTutor: (state) => state.showTutor,
        loading: (state) => state.loading,
        messages: (state) => state.messages,
        questionHtml: (state) => {
            return state.currentQuestion && state.math
                ? state.math.formatQuestionChecked(
                      state.currentQuestion,
                      'inGame',
                  )
                : '';
        },
        showPlayerCtas: (state) => state.showPlayerCtas,
        playerCtaType: (state) => state.playerCtaType,
        showAudioReplayButtons: (state) => state.showAudioReplayButtons,
        topic: (state, getters, rootState, rootGetters) =>
            rootGetters['v2/homegame/topic'],
        aiCredits: (state, getters, rootState, rootGetters) => {
            return rootGetters.user?.studentInfo?.battlepass?.aiCredits || 0;
        },
        hasEnoughAiCreditsToCallTutor: (state, getters) => {
            return getters.aiCredits >= TUTOR_CALL_COST_IN_AI_CREDITS;
        },
    },
    mutations: {
        setCurrentQuestion: (state, question) =>
            (state.currentQuestion = question),
        init: (state, payload) => {
            alreadyClosing = false;
            state.showTutor = true;
            state.loading = true;
            state.playerCtaType = PLAYER_CTA_TYPES.EXPLAIN_DIFFERENTLY;
            state.math = payload.math;
            state.playerAvatar = payload.playerAvatar;
            state.initialized = true;
            state.loading = false;

            const pollyKey = import.meta.env.VITE_POLLY_KEY;

            const pollyRegion = pollyKey.split(':')[0];

            state.pollyClient = new PollyClient({
                region: pollyRegion,
                credentials: fromCognitoIdentityPool({
                    clientConfig: { region: pollyRegion },
                    identityPoolId: pollyKey,
                }),
            });

            state.playersWrongAnswerValue = null;
        },
        reset: (state) => {
            state.initialized = false;
            state.messages = [];
            state.messageAnimationPromises = [];
            state.math = null;
            state.playerAvatar = null;
            state.currentQuestion = null;
            state.showTutor = false;
            state.loading = false;
            state.showPlayerCtas = false;
            state.playerCtaType = 'explainDifferent';
            state.lastAiMessageId = null;
            state.playersWrongAnswerValue = null;
            state.wrongAnswerHintUsed = null;
            clearTimeout(correctAnswerTimeoutHandler);
            clearTimeout(incorrectAnswerTimeoutHandler);
            state.pollyBufferSourceNode = null;
            state.pollyClient = null;
            requestAbortController = new AbortController();
        },
        addMessage: (state, payload) => {
            state.messages.push(payload);
        },
        setLoading: (state, next) => {
            state.loading = next;

            if (state.loading) {
                document
                    .getElementById('ai-tutor-loader')
                    ?.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
        },
        setPlayerCtasVisible: (state, next) => {
            if (next) {
                const messages = state.messages;

                let lastMessage = messages[messages.length - 1];

                if (lastMessage && lastMessage.closingMessage) {
                    return;
                }
            }

            state.showPlayerCtas = next;
        },
        setPlayerCtaType: (state, next) => {
            state.playerCtaType = next;
        },
        setLastAiMessageId: (state, next) => {
            state.lastAiMessageId = next;
        },
        setWrongAnswerHintUsed: (state, next) => {
            state.wrongAnswerHintUsed = next;
        },
        setPollyBuffer: (state, next) => {
            state.pollyBufferSourceNode = next;
        },
        setPlayersWrongAnswer: (state, next) => {
            state.playersWrongAnswerValue = next;
        },
    },
    actions: {
        init: async (store, payload) => {
            console.debug('store::aiTutor::init', payload);

            store.commit('init', payload);
            store.commit('setLoading', true);

            // No idea why it works, but it fixes polly sound in mobile safari
            const audio = store.rootGetters.getAudio;
            audio.play();
            audio.pause();

            store.dispatch('addMessage', {
                from: Participants.Moderator,
                avatar: null,
                content: `Asking MathPal to help you with: ${store.getters.questionHtml}`,
                animate: false,
            });

            try {
                const body = {
                    task: store.getters.questionHtml,
                    usedToday: store.state.usedToday,
                    context: {
                        topic: store.state.math._skill,
                        currentQuestion: store.state.currentQuestion,
                    },
                };

                const response = await SecureApi().post(
                    '/ai-tutor/first-instruction',
                    body,
                    { signal: requestAbortController.signal },
                );

                const { success, error, data } = response.data;

                if (success) {
                    sessionStorage.setItem('aiTutor/usedToday', 'true');

                    store.dispatch('v2/user/update', data, { root: true });
                    store.commit('setLastAiMessageId', data.messageId);

                    const reply = data;

                    store.commit('setLoading', false);

                    await store.dispatch('addMessage', {
                        id: data.id,
                        from: Participants.AI,
                        avatar: store.state.aiAvatar,
                        header: reply.header,
                        content: reply.body,
                        footer: reply.footer,
                        readText: true,
                    });

                    new TrackingService().track(
                        EVENTS.SEES_AI_TUTOR_FIRST_INSTRUCTION,
                    );

                    new TrackingService().track(
                        EVENTS.SET_AI_CREDITS,
                        data.studentInfo?.battlepass?.aiCredits || 0,
                    );

                    store.commit('setPlayerCtasVisible', true);
                } else {
                    console.error(`store::aiTutor server error: ${error}`);

                    store.dispatch('mathPalIsOnBreak', 'init');
                }
            } catch (err) {
                if (['AbortError', 'CanceledError'].includes(err.name)) {
                    return console.log(
                        'store::aiTutor::init canceled:',
                        err.message,
                    );
                }
                if (
                    await store.dispatch('checkForNotEnoughCreditsError', err)
                ) {
                    return;
                }

                console.error(`store::aiTutor::init request error: ${err}`);

                store.dispatch('mathPalIsOnBreak', 'init');
            }

            console.debug('store::aiTutor::initialized');
        },
        addMessage: async (store, message) => {
            // console.log('store::aiTutor::addMessage', message);

            const messages = store.getters.messages;

            let lastMessage = messages[messages.length - 1];

            if (
                lastMessage !== message &&
                lastMessage &&
                lastMessage.closingMessage
            ) {
                console.warn(
                    'store::aiTutor::addMessage closing message already added',
                );
                return;
            }

            store.commit('addMessage', message);

            if (message.readText) {
                await store.dispatch(
                    'readTextWithAwsPolly',
                    `${message.header ?? ''} ${message.content} ${
                        message.footer ?? ''
                    }`,
                );
            }

            return new Promise((resolve) => {
                // console.log('store::aiTutor::resolve', message);

                store.state.messageAnimationPromises.push(resolve);
            });
        },
        closeTutor: (store, manualClose = false) => {
            alreadyClosing = true;

            console.debug('store::aiTutor::closeTutor');

            store.dispatch('stopPollyAudio');
            store.commit('reset');

            new TrackingService().track(EVENTS.CLOSED_AI_TUTOR, {
                manually: manualClose,
            });
        },
        async setMessageAnimated(store, messageIndex) {
            if (store.state.messages[messageIndex]?.finishedAnimating) {
                return;
            }

            store.state.messages[messageIndex].finishedAnimating = true;
            store.state.messageAnimationPromises[messageIndex]();
        },
        async readTextWithAwsPolly(store, text) {
            console.debug('store::aiTutor::readTextWithAwsPolly', text);

            store.state.showAudioReplayButtons = false;

            try {
                const pollyClient = store.state.pollyClient;

                // Fix Polly reading "2-1" as "2 to 1".
                text = text.replaceAll('-', 'minus');

                // Remove emojis.
                text = text.replace(
                    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
                    '',
                );

                if (!pollyClient) {
                    console.debug('Polly client is missed', pollyClient);
                    return;
                }

                const pollyRes = await pollyClient.send(
                    new SynthesizeSpeechCommand({
                        OutputFormat: 'mp3',
                        SampleRate: '16000',
                        Text: text,
                        TextType: 'text',
                        VoiceId: 'Ruth',
                        Engine: 'neural',
                    }),
                );

                const audioContext = new AudioContext();

                const pollyBufferSourceNode = audioContext.createBufferSource();

                pollyBufferSourceNode.buffer =
                    await audioContext.decodeAudioData(
                        (await pollyRes.AudioStream.transformToByteArray())
                            .buffer,
                    );

                pollyBufferSourceNode.connect(audioContext.destination);

                if (!alreadyClosing) {
                    pollyBufferSourceNode.start();
                }

                pollyBufferSourceNode.onended = () => {
                    store.state.showAudioReplayButtons = true;
                };

                store.commit('setPollyBuffer', pollyBufferSourceNode);
            } catch (error) {
                store.state.showAudioReplayButtons = true;

                console.error('aiTutor::readTextWithAwsPolly', error);
            }
        },
        stopPollyAudio(store) {
            if (store.state.pollyBufferSourceNode) {
                try {
                    store.state.pollyBufferSourceNode.stop();
                } catch (e) {
                    // Somehow there is no audio yet or smth else is off.
                    console.warn('store::aiTutor::stopPollyAudio', e);
                }
            }
        },
        async playerFiguredItOut(store) {
            store.commit('setPlayerCtasVisible', false);

            const message =
                'That is great! Feel free to submit your answer then, I am here to help you further if needed.';

            await store.dispatch('readTextWithAwsPolly', message);

            await store.dispatch('addMessage', {
                from: Participants.AI,
                avatar: store.state.aiAvatar,
                content: message,
            });
        },
        async playerAskedForDifferentExplanation(store) {
            store.commit('setPlayerCtasVisible', false);

            store.dispatch('addMessage', {
                from: Participants.Moderator,
                avatar: null,
                content: `Asking MathPal to explain differently: ${store.getters.questionHtml}`,
                animate: false,
            });

            store.commit('setLoading', true);

            store.commit('setWrongAnswerHintUsed', false);

            new TrackingService().track(
                EVENTS.ASKED_AI_TUTOR_TO_EXPLAIN_DIFFERENTLY,
                {
                    topic: store.getters.topic,
                },
            );

            try {
                const body = {
                    task: store.getters.questionHtml,
                    context: {
                        topic: store.state.math._skill,
                        currentQuestion: store.state.currentQuestion,
                    },
                    lastMessageId: store.getters.lastAiMessageId,
                };

                const response = await SecureApi().post(
                    '/ai-tutor/explain-differently',
                    body,
                    { signal: requestAbortController.signal },
                );

                const { success, error, data } = response.data;

                if (success) {
                    store.dispatch('v2/user/update', data, { root: true });
                    store.commit('setLastAiMessageId', data.messageId);

                    const reply = data;

                    store.commit('setLoading', false);

                    await store.dispatch('addMessage', {
                        from: Participants.AI,
                        avatar: store.state.aiAvatar,
                        header: reply.header,
                        content: reply.body,
                        footer: reply.footer,
                        readText: true,
                    });

                    store.commit('setPlayerCtasVisible', true);
                } else {
                    console.error(`store::aiTutor server error: ${error}`);

                    store.dispatch('mathPalIsOnBreak', 'differentExplain');
                }
            } catch (err) {
                if (['AbortError', 'CanceledError'].includes(err.name)) {
                    return console.log(
                        'store::aiTutor::differentExplain canceled:',
                        err.message,
                    );
                }

                if (
                    await store.dispatch('checkForNotEnoughCreditsError', err)
                ) {
                    return;
                }

                console.error(
                    `store::aiTutor::differentExplain request error: ${err}`,
                );

                store.dispatch('mathPalIsOnBreak', 'differentExplain');
            }
        },
        async playerAskedForDiffExplanationAfterWrongAnswer(store) {
            store.commit('setPlayerCtasVisible', false);

            store.dispatch('addMessage', {
                from: Participants.Moderator,
                avatar: null,
                content: `Asking MathPal to explain differently: ${store.getters.questionHtml}`,
                animate: false,
            });

            store.commit('setLoading', true);

            store.commit('setWrongAnswerHintUsed', false);

            new TrackingService().track(
                EVENTS.ASKED_AI_TUTOR_TO_EXPLAIN_DIFFERENTLY,
                {
                    topic: store.getters.topic,
                },
            );

            store.commit('setLoading', true);

            try {
                const body = {
                    task: store.getters.questionHtml,
                    answer: store.state.playersWrongAnswerValue,
                    context: {
                        topic: store.state.math._skill,
                        currentQuestion: store.state.currentQuestion,
                    },
                    lastMessageId: store.getters.lastAiMessageId,
                };

                const response = await SecureApi().post(
                    '/ai-tutor/wrong-answer',
                    body,
                    { signal: requestAbortController.signal },
                );

                const { success, error, data } = response.data;

                if (success) {
                    store.dispatch('v2/user/update', data, { root: true });
                    store.commit('setLastAiMessageId', data.messageId);

                    const reply = data;

                    store.commit('setLoading', false);

                    await store.dispatch('addMessage', {
                        from: Participants.AI,
                        avatar: store.state.aiAvatar,
                        header: reply.header,
                        content: reply.body,
                        footer: reply.footer,
                        readText: true,
                    });

                    store.commit('setPlayerCtasVisible', true);
                } else {
                    console.error(`store::aiTutor server error: ${error}`);

                    store.dispatch('mathPalIsOnBreak', 'incorrectAnswer');
                }
            } catch (err) {
                if (['AbortError', 'CanceledError'].includes(err.name)) {
                    return console.log(
                        'store::aiTutor::incorrect canceled:',
                        err.message,
                    );
                }

                if (
                    await store.dispatch('checkForNotEnoughCreditsError', err)
                ) {
                    return;
                }

                console.error(
                    `store::aiTutor::incorrect request error: ${err}`,
                );

                store.dispatch('mathPalIsOnBreak', 'incorrectAnswer');
            }
        },
        async playerAnsweredIncorrectly(store, answerData) {
            console.log('store::aiTutor::wrongAnswer');

            if (!store.getters.showTutor || store.state.wrongAnswerHintUsed) {
                console.log(
                    'store::aiTutor no tutor or already seen the wrong answer hint',
                );

                return;
            }

            const currentSkillType = store.state.math._skill.type;

            if (
                !checkIfMistakeTooltipShouldBeShown(
                    currentSkillType,
                    store.state.currentQuestion,
                )
            ) {
                new TrackingService().track(
                    EVENTS.ANSWERED_INCORRECT_WITH_AI_TUTOR_OPEN,
                    {
                        topic: store.getters.topic,
                    },
                );

                // In a game where the player can't try again on a mistake.
                store.commit('setPlayerCtasVisible', false);

                store.dispatch('stopAllMessageAnimations');

                store.dispatch('stopPollyAudio');

                await store.dispatch('addMessage', {
                    from: Participants.AI,
                    avatar: store.state.aiAvatar,
                    content:
                        'Not quite, keep trying. I can explain again on the new question if you wish.',
                    readText: true,
                });

                store.commit('setPlayerCtasVisible', true);

                store.commit('setWrongAnswerHintUsed', true);

                return;
            }

            if (!store.getters.showPlayerCtas) {
                // Since the player can answer this question again, then
                // wait for the explanation to play out.
                console.log(
                    'aiTutor::playerAnsweredIncorrectly waiting for the AI to finish talking...',
                );

                const loadDebounced = () => {
                    clearTimeout(incorrectAnswerTimeoutHandler);

                    incorrectAnswerTimeoutHandler = setTimeout(() => {
                        store.dispatch('playerAnsweredIncorrectly', answerData);
                    }, 2000);
                };

                return loadDebounced();
            }

            store.commit('setWrongAnswerHintUsed', true);

            new TrackingService().track(
                EVENTS.ANSWERED_INCORRECT_WITH_AI_TUTOR_OPEN,
                {
                    topic: store.getters.topic,
                },
            );

            store.commit('setPlayerCtasVisible', false);

            store.commit('setPlayersWrongAnswer', answerData.answer);

            store.dispatch('addMessage', {
                from: Participants.AI,
                avatar: store.state.aiAvatar,
                content: `Let's see if we can come up with the right answer together. I can offer some clues if you'd like.`,
                animate: false,
                readText: true,
            });

            store.commit('setPlayerCtaType', PLAYER_CTA_TYPES.WRONG_ANSWER);

            store.commit('setPlayerCtasVisible', true);
        },
        async playerAnsweredCorrectly(store) {
            console.log('store::aiTutor::playerAnsweredCorrectly');

            if (!store.getters.showTutor || alreadyClosing) {
                console.log(
                    'store::aiTutor::playerAnsweredCorrectly stop',
                    store.getters.showTutor,
                    alreadyClosing,
                );

                return;
            }

            // correct answer triggers closing of AiTutor.
            requestAbortController.abort();

            store.commit('setLoading', false);

            store.commit('setWrongAnswerHintUsed', false);

            store.dispatch('stopAllMessageAnimations');

            store.dispatch('stopPollyAudio');

            store.commit('setPlayerCtasVisible', false);

            new TrackingService().track(
                EVENTS.ANSWERED_CORRECT_WITH_AI_TUTOR_OPEN,
                {
                    topic: store.getters.topic,
                },
            );

            const message =
                'You got it right! Great work. Feel free to reach out to me again if you need some additional help';

            store.commit('setPlayerCtasVisible', false);

            await store.dispatch('addMessage', {
                from: Participants.AI,
                avatar: store.state.aiAvatar,
                content: message,
                closingMessage: true,
                readText: true,
            });

            store.commit('setPlayerCtasVisible', false);

            alreadyClosing = true;

            correctAnswerTimeoutHandler = setTimeout(() => {
                store.dispatch('closeTutor');
            }, 2000);
        },
        stopAllMessageAnimations(store) {
            console.log('stop animation', store.getters.messages);

            const messages = store.getters.messages;

            for (let index = 0; index < messages.length; index++) {
                const message = messages[index];

                if (!message.finishedAnimating) {
                    console.log('stop animation');

                    store.state.messages[index].finishedAnimating = true;

                    if (store.state.messageAnimationPromises[index]) {
                        store.state.messageAnimationPromises[index]();
                    }
                }
            }
        },
        async mathPalIsOnBreak(store, stage) {
            new TrackingService().track(EVENTS.SEES_AI_TUTOR_ON_PAUSE, {
                topic: store.getters.topic,
                chatStage: stage,
            });

            store.commit('setLoading', false);

            await store.dispatch('addMessage', {
                from: Participants.Moderator,
                avatar: null,
                content: 'MathPal is out on a break. Please try again later!',
                readText: true,
            });
        },
        async checkForNotEnoughCreditsError(store, error) {
            const errMessage = error.response?.data?.error?.message;

            if (errMessage === 'NotEnoughAiCreditsError') {
                store.commit('setLoading', false);

                new TrackingService().track(
                    EVENTS.NOT_ENOUGH_AI_CREDITS_TO_START,
                );

                await store.dispatch('addMessage', {
                    from: Participants.Moderator,
                    avatar: null,
                    content: `Each call to MathPal requires 20 credits. You have ${store.getters.aiCredits}. Use the green plus icon button to get more credits.`,
                    readText: true,
                });

                return true;
            }

            return false;
        },
        updateAiTutorResult: async (store, { id, isHelpful }) => {
            try {
                await SecureApi().patch(`/ai-tutor/result/${id}`, {
                    isHelpful,
                });
            } catch (err) {
                console.error(`[updateAiTutorResult] request error: ${err}`);
            }
        },
    },
};
