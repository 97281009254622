import { LOG_LEVEL, Purchases } from '@revenuecat/purchases-capacitor';
import store from '@/store';
import SecureApi from '@/flows/Authentication/services/SecureApi';
import { app } from '@/main';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService.js';
import moment from 'moment';
import { Capacitor } from '@capacitor/core';

export const isInAppPaymentsAvailable = () =>
    window.Capacitor?.isPluginAvailable('Purchases');

export const managementURL = async () =>
    Capacitor?.isNativePlatform() &&
    (await Purchases?.isConfigured())?.isConfigured &&
    (await Purchases?.getCustomerInfo())?.customerInfo?.managementURL;

export async function revenueCatInAppPurchase(user, offering, product) {
    if (!isInAppPaymentsAvailable()) return;

    console.debug('== InApp payment step', await Purchases.getCustomerInfo());
    try {
        const offerings = await Purchases.getOfferings();

        const discountString =
            moment(
                user.paymentInfo?.stripe?.discount?.redeem_by * 1000,
            ).isAfter(moment()) && product == 'monthly'
                ? '_discount'
                : '';

        const targetProduct = `$rc_${product}${discountString}`;

        const packageToBuy =
            // offerings?.all?.[offering][product] ??
            offerings?.all?.[offering].availablePackages.find(
                ({ identifier }) => identifier == targetProduct,
            );

        console.debug('== discount?', {
            redeem_by: user.paymentInfo?.stripe?.discount?.redeem_by,
            moment: +moment(),
            offering,
            discountString,
            packageToBuy,
        });

        console.debug('== offerings', offerings);

        try {
            // this is for reference now
            await Purchases.checkTrialOrIntroductoryPriceEligibility([
                packageToBuy.product?.identifier,
            ]);
            const promotionalOffer = await Purchases.getPromotionalOffer({
                product: packageToBuy.product,
                discount: {
                    identifier: 'signup_48h_50_percent',
                },
            });

            console.debug('== promotionalOffer', promotionalOffer);
        } catch (e) {
            console.warn('== promotionalOffer error', e);
        }

        // here is an option to call for iOS purchaseDiscountedPackage (requires promotionalOffer
        const purchaseResult = await Purchases.purchasePackage({
            aPackage: packageToBuy,
        });
        console.debug('== purchaseResult', purchaseResult);
        return purchaseResult;
    } catch (e) {
        console.warn('== payment error', e);
    }
}

// Specific subscription purchase handling
export async function revenueCatSubscriptionPurchase(
    user,
    { stripe: { metadata, recurring } = {} } = {},
) {
    const data = store.dispatch('v2/user/fetchAndStoreUserInfo', {
        includeToResponse: 'paymentInfo',
    });

    if ('active' === data?.[0]?.paymentInfo?.stripe?.subscription?.status) {
        // this is very unusual situation where user paid subscription in another app instance
        // in the future this may have better UX e.g. presenting proper modal
        console.debug('=== subscription already active');
        location.reload();
    }

    const product = {
        life: 'lifetime',
        month: 'monthly',
        year: 'annual',
    }[(metadata?.lifetimeSubscription && 'life') || recurring?.interval];
    const purchaseResult = await revenueCatInAppPurchase(
        user,
        'math_pass_default',
        product,
    );
    // const purchaseResult = await commonInAppPurchase(user, `math_pass_default_${product}`);
    if (purchaseResult?.customerInfo?.entitlements?.active?.['math-pass']) {
        return store.dispatch('v2/user/fetchAndStoreUserInfo', {
            includeToResponse: 'paymentInfo',
        });
    }
}

export async function revenueCatMbucksPackPurchase(
    user,
    { unit_amount, metadata: { itemsAmount } },
    price,
) {
    const paymentAttempt = {
        paymentMethod: 'inapp', // store.state.paymentMethodType,
        cameFrom: sessionStorage.getItem('oneTimePaymentOpenLocation'),
        productPrice: price,
        productAmount: unit_amount,
    };
    // sessionStorage.setItem('paymentAttempt', JSON.stringify(paymentAttempt));

    const purchaseResult = await revenueCatInAppPurchase(
        user,
        'mbucks_default',
        `mbucks_${itemsAmount}`,
    );
    if (purchaseResult) {
        app.config.globalProperties.$emitter.emit('openAfterPurchaseModal', {
            amount: itemsAmount,
            price: (unit_amount / 100).toFixed(2),
            productType: 'mbucks',
        });
        await store.dispatch('v2/user/fetchAndStoreUserInfo', {
            includeToResponse: 'studentInfo',
        });
    }
    new TrackingService().track(EVENTS.ATTEMPTED_TO_BUY, {
        success: !!purchaseResult,
        ...paymentAttempt,
    });
    if (purchaseResult) {
        new TrackingService().track(EVENTS.BOUGHT_MBUCKS_PACK, {
            amount: unit_amount,
            price: price,
            mBucks: user?.studentInfo?.battlepass?.gems || 0,
        });
        new TrackingService().track(
            EVENTS.SET_MBUCKS,
            user.studentInfo?.battlepass?.gems || 0,
        );
    }
}

export async function initInAppPurchases(isAndroid) {
    if (!isInAppPaymentsAvailable()) {
        console.warn(
            '=== native InApp purchases not supported by this mobile build',
        );
        return;
    }

    await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs

    let firstRevenueCatConfig = true;
    store.watch(
        (state) => state.user,
        async (newUser, oldUser) => {
            const no3pmRestrictionAccounts = [].concat(
                import.meta.env.VITE_MOBILE_NO_3PM_RESTRICTION_ACCOUNTS?.split(
                    ',',
                ),
            );

            // to allow review by appstore, google play
            if (
                no3pmRestrictionAccounts.includes(newUser.username) ||
                no3pmRestrictionAccounts.includes(newUser.email)
            ) {
                console.debug(
                    '=== user is allowed to access purchases anytime',
                );
                if (store.getters.isBefore3PM) {
                    await store.dispatch('switchIsBefore3PM');
                }
            }

            console.log('=== user data changed', newUser, oldUser);
            try {
                const appUserID = (await Purchases.isConfigured())?.isConfigured
                    ? (
                          await Purchases.getAppUserID().catch((e) =>
                              console.warn(
                                  'cannot get revenuecat app user id',
                                  e,
                              ),
                          )
                      ).appUserID
                    : null;
                if (
                    (appUserID !== newUser.userId && newUser.userId) ||
                    firstRevenueCatConfig
                ) {
                    console.debug(
                        '=== logging in revenuecat user',
                        newUser.email,
                        appUserID && ', old appUserID: ' + appUserID,
                    );
                    if ((await Purchases.isConfigured()).isConfigured) {
                        await Purchases.logIn({ appUserID: newUser.userId });
                    } else {
                        const apiKey = isAndroid
                            ? import.meta.env
                                  .VITE_REVENUECAT_ANDROID_API_KEY_PUB
                            : import.meta.env.VITE_REVENUECAT_IOS_API_KEY_PUB;

                        await Purchases.configure({
                            apiKey,
                            appUserID: newUser.userId,
                        });
                    }
                    await Purchases.setEmail({ email: newUser.email });
                }
                // if (newUser.userId !== oldUser?.userId || firstRevenueCatConfig) {
                //     const { customerInfo } = await Purchases.restorePurchases();
                //     await updateUserSubscriptionFromRevenueCat(
                //         customerInfo,
                //         newUser,
                //     );
                // }
            } catch (e) {
                console.warn('cannot init InApp purchases', e);
            }
            firstRevenueCatConfig = false;
        },
    );
}
